import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {catchError, map, Observable, of} from 'rxjs';

import {OrganizationService} from '../services';
import {Organization} from '../../shared/models';
import {BillingPlanStore} from 'src/app/shared/state';

const preCanActivate = (
  url: string,
  org: Organization,
  router: Router
): boolean => {
  if (org?.status !== 'INACTIVE') {
    if (url === '/canceled') {
      router.navigate(['/']).then();
      return false;
    } else {
      return true;
    }
  } else {
    if (url !== '/canceled') {
      router.navigate(['/canceled']).then();
    }
    return true;
  }
};

export const CanceledPlanGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router: Router = inject(Router);
  const billingPlanStore = inject(BillingPlanStore);
  const organizationService: OrganizationService = inject(OrganizationService);
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  let org: Organization = billingPlanStore.getPlan()
    ? billingPlanStore?.getPlan()?.organization
    : organization;

  if (org && Object?.keys(org)?.length !== 0) {
    return preCanActivate(state.url, org, router);
  } else {
    return organizationService.getOrganization().pipe(
      map((res): boolean => {
        if (res) {
          org = res;
          localStorage.setItem('organization', JSON.stringify(res));
          organizationService.setOrganizationState(res);
          return preCanActivate(state.url, org, router);
        } else {
          return true;
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
};
